<template>
    <div class="main">
        <!--罗马集市订单详情入驻权，分销码导航栏--->
        <section class="top">
            <van-nav-bar title="产品详情" safe-area-inset-top z-index="1000" left-arrow :fixed="isTop"
                @click-left="onClickLeft" />
        </section>
        <!-- 订单状态 -->
        <section class="willPay">
            <div class="willPayD1">
                <img v-show="goodsDetail.orderStatus == '0'" src="@/assets/img/willPay04.png" alt="" />
                <img v-show="goodsDetail.orderStatus == '1'" src="@/assets/img/list-icon.png" alt="" />
            </div>
            <p>{{ goodsDetail.orderStatus == '0' ? '待使用' : goodsDetail.orderStatus == '1' ? '已使用' : '暂无状态' }}</p>
        </section>
        <!-- 商品信息 -->
        <section class="shopInfo">
            <div class="goodsInfo">
                <div class="goodsList">
                    <img class="goodsImg" :src="goodsDetail.picUrls" alt="暂无图片，请退出重试" />
                    <div class="goodsText">
                        <div>
                            <p>{{ goodsDetail.name || '--' }}</p>
                            <!-- <div class="size fontColor">
                                <span>规格：</span>
                                <span>x</span>
                            </div> -->
                        </div>
                        <div class="price">
                            <p style="color: #000;">价格：</p>
                            <p>{{ goodsPrice.length > 1 ? goodsPrice.join('|') : goodsPrice.length > 0 ? goodsPrice[0] :
                                '---' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 订单编号等信息 -->
        <section class="orderInfo">
            <div class="orderNum">
                <span>订单编号</span>
                <p class="orderNumP">
                    <span>{{ goodsDetail.id || '--' }}</span>
                    <span class="copy clipboard" @click="copyOrderId('.clipboard')"
                        :dataClipboard="goodsDetail.id">复制</span>
                </p>
            </div>
            <div class="orderNum">
                <span>付款时间</span>
                <span>{{ goodsDetail.payTime || '---' }}</span>
            </div>
            <div v-show="goodsDetail.orderStatus == '1'" class="orderNum">
                <span>使用时间</span>
                <span class="massage fontColor">{{ goodsDetail.useTime || '---' }}</span>
            </div>
        </section>
        <!-- 到底提醒 -->
        <section class="footer">
            <!-- <p>——已经到底啦——</p> -->
        </section>
        <!-- 支付或取消订单 -->
        <section class="payment">
            <van-tabbar :fixed="isBottom">
                <div class="paymentBtn">
                    <img class="btnImg" @click="contactService" src="@/assets/img/kefu.png" alt="" />
                    <a class="btnA" ref="tels">客服</a>
                </div>
                <div v-show="goodsDetail.orderStatus == '1'"></div>
                <button v-show="goodsDetail.orderStatus == '0' && goodsDetail.type >= 3" class="buy"
                    @click="handlerUse"><span>去使用</span></button>
            </van-tabbar>
        </section>
        <van-popup class="popup" position="bottom" round v-model="show">
            <p class="popup-p1">分销码</p>
            <div class="codeQR" v-for="(item) in codeList" :key="item.code">
                <p class="codeQR-1">分销码<span v-show="item.status == '1'">(已使用)</span></p>
                <p class="clipboardP"><span>{{ item.code }}</span> &ensp; &ensp;<span class="clipboardSpan"
                        @click="copyOrderId('.clipboardSpan')" :dataClipboard="item.code"
                        v-show="item.status == '0'">复制</span></p>
                <!-- <p class="codeQR-2">有效期至：{{ codeDate }}</p> -->
            </div>
        </van-popup>
    </div>
</template>
  
<script>
import { Toast } from "vant";
import {
    getTelephone
} from "@/api/yundian/orderIdDetail/index.js";
import { getOrderDetail, getSellCode } from '@/api/romaMarket/index.js'
import ClipboardJS from "clipboard";
export default {
    data() {
        return {
            show: false,//二维码弹出层
            isTop: true,//顶部返回
            isBottom: true,//底部按钮
            goodsDetail: {},//商品信息
            telephoneNumber: '',//客服电话
            base64Img: '',//二维码
            goodsPrice: [],
            codeList: [],//分销码
            // codeDate: '',//有效期

        };
    },
    beforeMount() {

    },
    activated() {

    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        this.$store.commit("setSession", this.$route.query.session);
        this.getPhone()
        this.getPageInfo()
    },

    methods: {
        //复制方法
        copyOrderId(dom) {
            // console.log(dom, '???');
            // 1. 绑定剪贴板操作方法(订单号)
            let clipboard = new ClipboardJS(dom, {
                text: function (trigger) {
                    // 2. trigger 就是点击的 dom 元素，所以就可以通过它来获取到它的属性 'dataClipboard' 的值了
                    // 3. 从这个方法返回的 string 就是会复制到剪贴板中的内容，所以可以复制任何内容了，也可以在这里处理一些逻辑
                    // 4. 我在这里就只是单纯的输出了事先绑定好的值
                    // console.log(trigger.getAttribute('dataClipboard'));
                    return trigger.getAttribute("dataClipboard");
                },
            });
            // 5. 当复制成功的时候提示复制成功
            clipboard.on("success", () => {
                // 还可以添加监听事件，如：复制成功后提示
                Toast.success("复制成功");
            });
        },
        getPageInfo() {
            let id = this.$route.query.id
            // let id = '1706874964541542401'
            getOrderDetail({ id: id }).then(res => {
                if (res.data.code == 0) {
                    // console.log(res.data.data);
                    this.goodsDetail = res.data.data
                    if (res.data.data.price && res.data.data.price > 0) {
                        this.goodsPrice.push('￥' + res.data.data.price)
                    }
                    if (res.data.data.postHorse && res.data.data.postHorse > 0) {
                        this.goodsPrice.push(res.data.data.postHorse + '驿马')
                    }
                    if (res.data.data.officialCertificate && res.data.data.officialCertificate > 0) {
                        this.goodsPrice.push(res.data.data.officialCertificate + '官凭')
                    }
                    if (res.data.data.goldLeaf && res.data.data.goldLeaf > 0) {
                        this.goodsPrice.push(res.data.data.goldLeaf + '金叶子')
                    }
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast.fail('请求失败，请退出重试')
            })
        },
        //回退
        onClickLeft() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.close.postMessage("up");
            }
            if (isAndroid) {
                window.android.close();
            }
        },
        //联系客服
        contactService() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                this.$refs.tels.href = 'tel://' + this.telephoneNumber;
                this.$refs.tels.click();
            }
            if (isAndroid) {
                window.android.callPhone('' + this.telephoneNumber);
            }
        },
        getPhone() {
            getTelephone().then(res => {
                if (res.data.code == 0) {
                    this.telephoneNumber = res.data.data
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast('客服繁忙中')
            })
        },
        //去使用
        handlerUse() {
            getSellCode({ id: this.goodsDetail.id }).then(res => {
                if (res.data.code == 0) {
                    this.codeList = res.data.data.list
                    // this.codeDate = res.data.data.date
                    this.show = true
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast('分销码获取失败')
            })
        }
    }
};
</script>
  
<style lang="less" scoped>
.main {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .popup {
        box-sizing: border-box;
        padding: 20px 20px;

        .popup-p1 {
            font-size: 16px;
            text-align: center;
        }

        .codeQR {
            padding: 10px;
            margin: 10px 0;
            background: #F6F6F6;

            .codeQR-1 {
                span {
                    color: #666666;
                    font-size: 12px;
                }
            }

            .clipboardP {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0;
                font-size: 14px;

                .clipboardSpan {
                    color: #E54320;
                    text-decoration: underline;
                }
            }

            .codeQR-2 {
                font-size: 12px;
                color: #999999;
            }
        }
    }

    section {
        background: #fff;
        width: 100%;
        box-sizing: border-box;
    }



    .fontColor {
        line-height: 0.7784rem;
        color: #666666;
        font-size: 0.3459rem;
    }

    .top {
        height: 6%;
        font-size: 1.2973rem;
    }

    .willPay {
        display: flex;
        padding: 0.4324rem 0.4324rem 0.6rem 0.4324rem;
        border-bottom: 1px solid #E5E5E5;

        .willPayD1 {
            width: 1rem;
            display: flex;
            align-items: center;
        }

        img {
            height: 0.6rem;
            width: 0.6rem;
            margin-right: 0.3rem;
        }
    }

    .shopInfo {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 0.5135rem;

        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding: 0.4324rem 0;
            border-bottom: 1px solid rgba(170, 170, 170, .4);

            .goodsList {
                display: flex;
                flex-flow: row nowrap;

                .goodsImg {
                    width: 3rem;
                    height: 3rem;
                }

                .goodsText {
                    margin-left: 10px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .price {
                        color: #E54320;
                        font-size: 12px;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .returnGoods {
                padding: 0.4324rem 0 0 0;
                display: flex;
                justify-content: flex-end;

                button {
                    max-width: 2.8rem;
                    min-width: 2.4rem;
                    height: 0.8649rem;
                    margin-right: 0.6919rem;
                    border: 1px solid rgb(202, 202, 202);
                    background: #fff;
                    border-radius: 0.4324rem;
                }

                .buy {
                    background: #E54320;
                    color: #fff;
                    border: none;
                }
            }

            .otherText {
                padding-top: 0.4324rem;

                .integrate {
                    display: flex;
                    justify-content: space-between;
                }

                .freight {
                    display: flex;
                    justify-content: space-between;
                }



                .remark {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

    }

    .orderInfo {
        padding: 0.4324rem 0.5135rem;
        line-height: 0.7568rem;

        .orderNum {
            display: flex;
            justify-content: space-between;

            .orderNumP {
                display: flex;
                align-items: center;
                justify-content: end;
            }

            .copy {
                color: #E54320;
                margin-left: 0.2595rem;
                text-decoration: underline;
            }

            .massage {
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 2;
                -moz-box-orient: vertical;
                overflow-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                overflow: hidden;
            }
        }
    }

    .payment {
        .van-tabbar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .paymentBtn {
                margin-left: 15px;
                padding: 15px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                .btnImg {
                    width: 0.6rem
                }

                .btnA {
                    color: #000;
                    font-size: 12px;
                }
            }

            button {
                width: 2.5946rem;
                height: 0.8649rem;
                margin-right: 0.6919rem;
                border: 1px solid #999999;
                background: #fff;
                border-radius: 0.4324rem;
            }

            .buy {
                background: #E54320;
                color: #fff;
                border: none;
            }

            .evaluate {
                border: none;
                background: #E54320;
                color: #fff;
            }

        }

    }

    .footer {
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
</style>
  