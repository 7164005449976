import { fetchGet } from '../../router/https'
//订单详情
export function getOrderDetail(params) {
    return fetchGet('/mallapp/orderfictitious/orderDetail', params);
}
//订单二维码
export function getOrderCode(params) {
    return fetchGet('/mallapp/orderfictitious/QRCodeScanning', params);
}
//订单分销码
export function getSellCode(params) {
    return fetchGet('/mallapp/orderfictitious/verificationCode', params);
}
